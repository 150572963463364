import withWidth from "@material-ui/core/withWidth"
import { graphql } from "gatsby"
import React from "react"
import { useSelector } from "react-redux"
import BlogTitle from "../components/BlogTitle"
import Cards from "../components/Cards"
import { Destinations } from "../components/Destinations/Destinations"
import Footer from "../components/Footer"
import Layout from "../components/Layout"
import Textfield from "../components/TextField"
import AboutSection from "../components/AboutSection"
import CustomDivider from "../components/CustomDivider"
import { SEO } from "../components/SEO"

export const query = graphql`
  query($locale: String!) {
    allContentfulLandingPageModel(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          tourSectionTitle
          destinationSectionTitle
          destinationSectionDescription
          blogSectionTitle
          blogSectionDescription
          generalText {
            content {
              content {
                nodeType
                value
              }
              nodeType
            }
          }
        }
      }
    }
    allContentfulBlogs(filter: { node_locale: { eq: $locale } }, limit: 4) {
      edges {
        node {
          slug
          blogPublishDate
          blogTitle
          extraBlogImages {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          blogMainImage {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          blogSubTitle {
            childMarkdownRemark {
              html
            }
          }
          blogDescription {
            childMarkdownRemark {
              html
            }
          }
          eachExtraBlogDescription {
            descriptions
          }
        }
      }
    }
    allContentfulDmcPageModel(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          topSectionPageTitle
          topSectionDescription {
            topSectionDescription
          }
        }
      }
    }
    allContentfulTourPageModel(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          pageTitle
          pageSubTitle
        }
      }
    }
    allContentfulInvestPageModel(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          pageTitle
          pageDescription {
            pageDescription
          }
        }
      }
    }
    allContentfulAboutPageModel(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          pageTitle
          bannerTitle
          bannerSubTitle
          bannerDescription {
            childMarkdownRemark {
              html
            }
          }
          bannerImage {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          video {
            file {
              url
            }
          }
        }
      }
    }
  }
`

const MainScreen = ({ data, location, pageContext }) => {
  const translations = useSelector(state => state?.locale?.localeObj?.pageData)
  const locale = useSelector(state => state?.locale?.localeObj?.locale)
  const landingPageData = data?.allContentfulLandingPageModel?.edges?.[0]?.node
  const aboutSectionData = data?.allContentfulAboutPageModel?.edges?.[0].node
  const blogs = data?.allContentfulBlogs?.edges

  return (
    <Layout location={location} pageContext={pageContext}>
      <SEO />
      <div className={locale === "zh" && "chinese-version"}>
        <Cards
          tourPageMainData={data.allContentfulTourPageModel?.edges?.[0]?.node}
          dmcPageMainData={data.allContentfulDmcPageModel?.edges?.[0]?.node}
          investPageMainData={
            data.allContentfulInvestPageModel?.edges?.[0]?.node
          }
          title={landingPageData?.tourSectionTitle}
        />
        <AboutSection
          sectionData={aboutSectionData}
          btnLabel={translations?.learnMore}
        />
        <CustomDivider extendLeft />
        <Destinations locale={pageContext.locale} />
        <CustomDivider extendRight />
        <BlogTitle
          title={landingPageData?.blogSectionTitle}
          description={landingPageData?.blogSectionDescription}
          blogs={blogs}
          viewAllString={translations?.viewAll}
        />
        <CustomDivider extendRight />
        <Textfield backgroundColor={"black"} locale={pageContext.locale} />
        <SEO
          seoData={{
            generalText: landingPageData?.generalText,
          }}
          isGenText
        />
        <CustomDivider extendLeft />
        <Footer locale={pageContext.locale} />
      </div>
    </Layout>
  )
}

export default withWidth()(MainScreen)
