import React from "react"
import {
  Typography,
  Button,
  Grid,
  Box,
  Hidden,
  Container,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import LocalizedLink from "./LocalizedLink"
import VideoPlayer from "./video"

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(20),
      marginBottom: theme.spacing(15),
    },
  },
  textBlock: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(4),
    },
  },
  title: {
    paddingTop: theme.spacing(1.5),
  },
  button: {
    marginTop: theme.spacing(3),
  },
}))

const AboutSection = ({ sectionData, btnLabel }) => {
  const {
    bannerTitle,
    bannerSubTitle,
    bannerDescription,
    bannerImage,
    video,
  } = sectionData

  const classes = useStyles()

  const VideoBlock = () => (
    <div className="video-section">
      <VideoPlayer
        video={video?.file?.url}
        poster={bannerImage?.fluid?.src}
      />
    </div>
  )

  const TextBlock = () => (
    <Box className={classes.textBlock}>
      <Typography variant="overline">{bannerTitle}</Typography>
      <Typography variant="h2" className={classes.title}>
        {bannerSubTitle}
      </Typography>
      <Typography
        variant="body1"
        dangerouslySetInnerHTML={{
          __html: bannerDescription?.childMarkdownRemark?.html,
        }}
      />
      <Button
        variant="outlined"
        component={LocalizedLink}
        to="/AboutScreen"
        className={classes.button}
      >
        {btnLabel}
      </Button>
    </Box>
  )

  return (
    // Use different grid layouts for mobile and desktop as video player needs
    // to stretch over gutter to padge edge
    <div className={classes.root}>
      <Hidden smDown>
        <Grid container justify="space-between" alignItems="center">
          <Grid item md={6}>
            <VideoBlock />
          </Grid>
          <Grid item md={6} container justify="center">
            <Grid item md={8}>
              <TextBlock />
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <Container>
          <Grid container justify="space-between" alignItems="center">
            <Grid item md={6}>
              <VideoBlock />
            </Grid>
            <Grid item md={6} container justify="center">
              <Grid item md={8}>
                <TextBlock />
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Hidden>
    </div>
  )
}

export default AboutSection
