import React from "react"
import Img from "gatsby-image"
import { makeStyles } from "@material-ui/core/styles"
import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
} from "@material-ui/core"
import LocalizedLink from "../LocalizedLink"

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardImg: {
    height: 174,
  },
  cardContent: {
    padding: theme.spacing(1),
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
}))

const BlogCard = ({ blog }) => {
  const classes = useStyles()
  const { blogMainImage, blogPublishDate, blogTitle, blogSubTitle, slug } = blog

  return (
    <Card variant="outlined" className={classes.root}>
      <CardActionArea component={LocalizedLink} to={`/blog/${slug}`}>
        <CardMedia>
          <Img fluid={blogMainImage.fluid} className={classes.cardImg} />
        </CardMedia>
        <div className={classes.cardContent}>
          <CardContent>
            <Typography variant="overline">
              {new Date(blogPublishDate).toLocaleDateString("en-gb", {
                year: "numeric",
                month: "short",
                day: "numeric",
              })}
            </Typography>
            <Typography variant="h6">{blogTitle}</Typography>
            <Typography
              // nested p tags causing unwanted whitespace. Content model needs to be fixed
              dangerouslySetInnerHTML={{
                __html: blogSubTitle.childMarkdownRemark.html,
              }}
            />
          </CardContent>
        </div>
      </CardActionArea>
    </Card>
  )
}

export default BlogCard
