import React from "react"
import Img from "gatsby-image"
import { makeStyles } from "@material-ui/core/styles"
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  CardActionArea,
} from "@material-ui/core"
import LocalizedLink from "../LocalizedLink"

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardImg: {
    height: 174,
  },
  countryFlag: {
    width: 24,
    clipPath: "circle(8px at center)",
    display: "inline-block",
  },
  cardContent: {
    padding: theme.spacing(1),
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  inline: {
    display: "inline-block",
  },
  actionArea: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
}))

const DestinationCard = ({ destination, btnLabel }) => {
  const classes = useStyles()
  const {
    bannerImage,
    cardTruncateDescription,
    cityName,
    countryFlag,
    slug,
  } = destination

  return (
    <Card variant="outlined" className={classes.root}>
      <CardActionArea
        component={LocalizedLink}
        to={`/destinations/${slug}`}
        className={classes.actionArea}
      >
        <CardMedia style={{ width: "100%" }}>
          <Img fluid={bannerImage.fluid} className={classes.cardImg} />
        </CardMedia>
        <div className={classes.cardContent}>
          <CardContent>
            <Img fluid={countryFlag.fluid} className={classes.countryFlag} />
            <Typography variant="h6" className={classes.inline}>
              {cityName}
            </Typography>
            <Typography>
              {cardTruncateDescription.cardTruncateDescription}
            </Typography>
          </CardContent>
          {/* <CardActions>
            <Button
              variant="outlined"
              endIcon={<ArrowForwardIcon />}
            >
              {btnLabel}
            </Button>
          </CardActions> */}
        </div>
      </CardActionArea>
    </Card>
  )
}

export default DestinationCard
