import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Box } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import DestinationCard from "./DestinationCard"
import CarouselSection from "../CarouselSection"
import { useSelector } from "react-redux"
import useRackSize from "../useRackSize"

const useStyles = makeStyles(theme => ({
  cardWrapper: {
    flexBasis: "25%",
    padding: theme.spacing(1.5),
  },
}))

const Destinations = ({ customTitle, locale }) => {
  const data = useStaticQuery(graphql`
    query destinationQuery {
      allContentfulLandingPageModel {
        edges {
          node {
            node_locale
            destinationSectionTitle
            destinationSectionDescription
          }
        }
      }
      allContentfulCityModel {
        edges {
          node {
            node_locale
            slug
            cityName
            countryFlag {
              fluid(maxWidth: 50) {
                ...GatsbyContentfulFluid
              }
            }
            cardTruncateDescription {
              cardTruncateDescription
            }
            bannerImage {
              fluid(maxWidth: 600) {
                ...GatsbyContentfulFluid
              }
            }
          }
        }
      }
    }
  `)

  const localeLandingData = data.allContentfulLandingPageModel.edges.filter(
    ({ node }) => node.node_locale === locale
  )
  const allDestinations = data.allContentfulCityModel.edges.filter(
    ({ node }) => node.node_locale === locale
  )

  const destinations = allDestinations.slice(0, 4)

  const translations = useSelector(state => state?.locale?.localeObj?.pageData)
  const btnLabel = translations?.learnMore
  const viewAllString = translations?.viewAll

  const title = localeLandingData[0].node.destinationSectionTitle
  const description = localeLandingData[0].node.destinationSectionDescription

  const classes = useStyles()
  const rackSize = useRackSize()

  return (
    <CarouselSection
      title={customTitle ? customTitle : title}
      description={description}
      cardsData={destinations}
      indexBtn={{ label: viewAllString, path: "/Destinations" }}
      rackSize={rackSize}
    >
      {destinations.map(({ node }) => (
        <Box
          key={node.slug}
          className={classes.cardWrapper}
          style={{ minWidth: `${100 / rackSize}%` }}
        >
          <DestinationCard destination={node} btnLabel={btnLabel} />
        </Box>
      ))}
    </CarouselSection>
  )
}
export { Destinations }
