import React from "react"
import { Box } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import BlogCard from "./BlogTitleCard"
import CarouselSection from "../CarouselSection"
import useRackSize from "../useRackSize"

const useStyles = makeStyles(theme => ({
  cardWrapper: {
    flexBasis: "25%",
    padding: theme.spacing(1.5),
  },
}))

const BlogTitle = ({ title, description, blogs, btnLabel, viewAllString }) => {
  const classes = useStyles()
  const rackSize = useRackSize()

  return (
    <CarouselSection
      title={title}
      description={description}
      btnLabel={btnLabel}
      indexBtn={{ label: viewAllString, path: "/blog" }}
      cardsData={blogs}
      rackSize={rackSize}
    >
      {blogs.map(({ node }) => (
        <Box
          key={node.slug}
          className={classes.cardWrapper}
          style={{ minWidth: `${100 / rackSize}%` }}
        >
          <BlogCard blog={node} />
        </Box>
      ))}
    </CarouselSection>
  )
}

export default BlogTitle
